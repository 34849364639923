import React from "react";
import { BrowserRouter, Route, Switch  } from "react-router-dom";


import Login from "../pages/Login";



export default function AuthRoutes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login}/>
            </Switch>
        </BrowserRouter>
    )
}


