import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import {Pie, LabelList ,PieChart,LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, BarChart , CartesianGrid , Tooltip, Bar, ComposedChart, Legend, Cell,} from 'recharts';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import ProgressCircle from "../../components/ProgressCircle";

import LlistItems from '../listItems';

import Title from '../Title';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import { Avatar } from '@material-ui/core';

import api from '../../services/api';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


  const [data, setData] = React.useState([])
  const [progress, setprogress] = React.useState(false)

  const [dasboardata, setDasboard] = React.useState(
    [
      { name: 'Contas', value: 0 },
      { name: 'Professores', value: 0 },
      { name: 'Escolas', value: 0 },
    ]
  )


  React.useEffect(()=>{

    async function Das_data(){

      try{
        setprogress(true)
        const value = await api.get('/dasboard')
        setDasboard(value?.data?.value)

        setData([...data, 
        
          { name: 'Contas', value: value?.data?.value?.user },
          { name: 'Professores', value: value?.data?.value?.professor },
          { name: 'Escolas', value: value?.data?.value?.escolas },
        ])

        setprogress(false)
      }catch{setprogress(false)}
      
    }

    Das_data()


  },[])
  

  return (
    <div className={classes.root}>
      <ProgressCircle Status={progress}/>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Dashboard
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List><LlistItems /></List>
        <Divider />

      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        
        <Container maxWidth="lg" className={classes.container}>

          
          <Grid container spacing={3}>
            <Grid item xs={12} md={2} lg={3}>
              <Paper className={classes.paper} >
                <section style={{display:"flex",flexDirection:"row", alignItems:"center", justifyContent:"space-between", alignContent:"center"}}>
                  <PeopleIcon style={{color:"#0088FE"}} />
                  <Title>Contas</Title>
                </section>
                <Title>{dasboardata?.user}</Title>
              </Paper>
            </Grid>

            <Grid item xs={12} md={2} lg={3}>
              <Paper className={classes.paper} >
                <section style={{display:"flex",flexDirection:"row", alignItems:"center", justifyContent:"space-between", alignContent:"center"}}>
                  <PeopleIcon  style={{color:"#00C49F"}} />
                  <Title>Professores</Title>  
                </section>
                <Title>{dasboardata?.professor}</Title>
              </Paper>
            </Grid>

            <Grid item xs={12} md={2} lg={3}>
              <Paper className={classes.paper} >
                <section style={{display:"flex",flexDirection:"row", alignItems:"center", justifyContent:"space-between", alignContent:"center"}}>
                  <SchoolIcon style={{color:"#FFBB28"}} />
                  <Title>Escolas</Title>
                </section>
                <Title>{dasboardata?.escolas}</Title>
              </Paper>
            </Grid>

            <Grid item xs={12} md={2} lg={3}>
              <Paper className={classes.paper} >
              <PieChart width={190} height={80} >
                <Tooltip/>
                <Pie
                  data={data}
                  cx={100}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
              </Paper>
            </Grid>

            </Grid>
            
          
          <Grid container spacing={3}>


            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits value={dasboardata?.ganhos}/>
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={classes.paper}>
                <Orders value={dasboardata?.compras} />
              </Paper>
            </Grid>
            {/* Recent Students*/}
            <Grid item xs={12} md={8} lg={3}>
              <Paper className={classes.paper}>
                <Title>Estudantes Recentes</Title>
                {
                  dasboardata?.userInfo?.slice(0,4).map(item => (
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom:"5px"}}>
                      <Avatar src={item?.perfil_url}/>
                      <section >
                        <Typography component="p" variant="h6">
                          {item?.nome}
                        </Typography>
                        <Typography color="textSecondary" className={classes.depositContext}>
                          {`Categoria ${item?.categoria}`}
                        </Typography>
                      </section>
                      <Typography color="textSecondary" className={classes.depositContext}>
                          há 2 dias
                      </Typography>
                    </div>
                  ))
                }

                
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}